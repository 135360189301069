import React from "react";
import Icon from "@material-ui/icons/Category";
import {Field} from 'react-final-form';
import {
    Create,
    Datagrid,
    DeleteWithConfirmButton,
    Edit,
    EditButton,
    Filter,
    FormTab,
    ImageField,
    List,
    maxLength,
    ReferenceField,
    ReferenceInput,
    required,
    SelectInput,
    Show,
    ShowButton,
    SimpleShowLayout,
    TabbedForm,
    TextField,
    TextInput,
} from "react-admin";
import ImageUpload from "../common/ImageUpload";
import {ListPagination} from "../common/Pagination";
import {SearchInput} from "../common/SearchInput";

export const CategoryIcon = Icon;
export const CategoryCreate = (props) => (
    <Create {...props}>
        <CreateOrEdit/>
    </Create>
);

export const CategoryEdit = (props) => (
    <Edit {...props}>
        <CreateOrEdit/>
    </Edit>
);

const CategoryFilters = (props) => (
    <Filter {...props}>
        <SearchInput source={'name.turkish'} alwaysOn label={"Adı"}/>

        <ReferenceInput source={'department.id'}
                        perPage={1000}
                        reference={'departments'}>
            <SelectInput optionText={'name.turkish'}/>
        </ReferenceInput>
    </Filter>
);
export const CategoryList = (props) => (
    <List {...props} bulkActionButtons={false}
          sort={{field: 'id', order: 'DESC'}}
          pagination={<ListPagination/>}
          perPage={50}
          filters={<CategoryFilters/>}>
        <CategoryGrid/>
    </List>
);

export const CategoryGrid = (props) => (
    <Datagrid {...props}>
        <TextField source={"name.turkish"}/>
        <ReferenceField source={'department.id'} reference={'departments'}>
            <TextField source={'name.turkish'}/>
        </ReferenceField>
        <TextField source={"description.turkish"}/>
        <ShowButton/>
        <EditButton/>
        <DeleteWithConfirmButton/>
    </Datagrid>
);
export const CategoryShow = (props) => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source={"name.turkish"}/>
            <TextField source={"description.turkish"}/>
            <ReferenceField source={'department.id'} reference={'departments'}>
                <TextField source={'name.turkish'}/>
            </ReferenceField>
            <ImageField source={"image"}/>
        </SimpleShowLayout>
    </Show>
);
const CreateOrEdit = (props) => (
    <TabbedForm {...props} redirect={"list"}>
        <FormTab label={"resources.categories.general"}>

            <TextInput source={"name.turkish"}
                       validate={[maxLength(255)]}/>

            <ReferenceInput source={'department.id'}
                            perPage={1000}
                            reference={'departments'} validate={required()}>
                <SelectInput optionText={'name.turkish'}/>
            </ReferenceInput>

            <TextInput source={"description.turkish"}
                       fullWidth
                       validate={[maxLength(1000000)]}/>

        </FormTab>
        <FormTab label={"resources.categories.fields.image"}>
            <Field name={"image"}
                   component={ImageUpload}
                   source={"image"}/>
        </FormTab>
    </TabbedForm>
);

export const CategoryTranslations = {
    en: {
        categories: {
            name: "Kategorilar",
            general: "Genel",
            fields: {
                name: {
                    turkish: "Adı",
                },
                image: "Resimi",
                description: {
                    turkish: "Tarifi",
                },
                department: {
                    id: "Departman"
                }
            },
        },
    }
};