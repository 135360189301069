import React from "react";

import {Datagrid, Edit, EditButton, List, SimpleForm, TextField, TextInput} from 'react-admin';

export const ConfigurationsList = props => (
    <List {...props} bulkActionButtons={false}>
        <Datagrid>
            <TextField source={"key"}/>
            <TextField source={"value"}/>
            <EditButton/>
        </Datagrid>
    </List>
);

export const ConfigurationEdit = props => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput source={'key'} disabled/>
            <TextInput source={'value'}/>
        </SimpleForm>
    </Edit>
);

export const ConfigurationStrings = {
    en: {
        appConfigs: {
            name: "Configuration",
            fields: {
                key: "Key",
                value: "Value"
            }
        }
    }
}