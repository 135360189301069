import React, {cloneElement} from "react";
import Icon from '@material-ui/icons/Extension';
import {Field} from 'react-final-form';
import {
    Create,
    CreateButton,
    Datagrid,
    DeleteWithConfirmButton,
    Edit,
    EditButton,
    ExportButton,
    Filter,
    FormTab,
    ImageField,
    List,
    maxLength,
    ReferenceManyField,
    required,
    sanitizeListRestProps,
    Show,
    ShowButton,
    Tab,
    TabbedForm,
    TabbedShowLayout,
    TextField,
    TextInput,
    TopToolbar,
    useListContext,
} from "react-admin";
import ImageUpload from "../common/ImageUpload";
import {CategoryGrid} from "./Category";
import {ListPagination} from "../common/Pagination";
import {SearchInput} from "../common/SearchInput";

const ListActions = (props) => {
    const {
        className,
        exporter,
        filters,
        maxResults,
        ...rest
    } = props;
    const {
        currentSort,
        resource,
        displayedFilters,
        filterValues,
        hasCreate,
        basePath,
        showFilter,
        total,
    } = useListContext();
    return (
        <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
            {filters && cloneElement(filters, {
                resource,
                showFilter,
                displayedFilters,
                filterValues,
                context: 'button',
            })}
            {hasCreate && <CreateButton basePath={basePath}/>}
            <ExportButton
                disabled={total === 0}
                resource={resource}
                sort={currentSort}
                filterValues={filterValues}
                maxResults={maxResults}
            />

        </TopToolbar>
    );
};

export const DepartmentIcon = Icon;
export const DepartmentCreate = (props) => (
    <Create {...props}>
        <CreateOrEdit/>
    </Create>
);

export const DepartmentEdit = (props) => (
    <Edit {...props}>
        <CreateOrEdit/>
    </Edit>
);

const DepartmentFilters = (props) => (
    <Filter {...props}>
        <SearchInput source={'name.turkish'} alwaysOn label={"Adı"}/>
    </Filter>
);
export const DepartmentList = (props) => (
    <List {...props} bulkActionButtons={false}
          actions={<ListActions/>}
          pagination={<ListPagination/>}
          perPage={50}
          sort={{field: 'time', order: 'DESC'}}
          filters={<DepartmentFilters/>}>
        <Datagrid>
            <TextField source={"name.turkish"}/>
            <TextField source={"description.turkish"}/>
            <ShowButton/>
            <EditButton/>
            <DeleteWithConfirmButton/>
        </Datagrid>
    </List>
);
export const DepartmentShow = (props) => (
    <Show {...props}>
        <TabbedShowLayout>
            <Tab label={'resources.departments.general'}>
                <TextField source={"name.turkish"}/>
                <TextField source={"description.turkish"}/>
                <ImageField source={"image"}/>
            </Tab>
            <Tab label={'resources.categories.name'}>
                <ReferenceManyField reference={'categories'} target={'department.id'} addLabel={false}>
                    <CategoryGrid/>
                </ReferenceManyField>
            </Tab>
        </TabbedShowLayout>
    </Show>
);
const CreateOrEdit = (props) => (
    <TabbedForm {...props} redirect={"list"}>
        <FormTab label={"resources.departments.general"}>

            <TextInput source={"name.turkish"}
                       validate={[maxLength(255)]}/>

            <TextInput source={"description.turkish"}
                       fullWidth
                       multiline
                       validate={[maxLength(1000000)]}/>
        </FormTab>
        <FormTab label={"resources.departments.fields.image"}>
            <Field name={"image"}
                   validate={required()}
                   component={ImageUpload}
                   source={"image"}/>
        </FormTab>
    </TabbedForm>
);

export const DepartmentTranslations = {
    en: {
        departments: {
            name: "Departmanlar",
            general: "Genel",
            fields: {
                name: {
                    turkish: "Adı",
                },
                image: "Resimi",
                description: {
                    turkish: "Tarifi",
                },
            },
        },
    }
};