import React from 'react';
import {Button, Dialog, DialogActions} from "@material-ui/core";
import SendIcon from '@material-ui/icons/Refresh';
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import {fetchJson} from "../rest/fetch";
import {SERVER_IP} from "../index";
import CircularProgress from "@material-ui/core/CircularProgress";
import {translate} from 'react-admin';

class ResetDeviceView extends React.Component {
    state = {};

    handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({errorTitle: false});
    };


    handleSend = () => {
        const {record} = this.props;
        if (record === undefined) {
            return
        }
        this.setState({loading: true});

        fetchJson(`${SERVER_IP}/users/resetDevice/${record.id}`,
            {method: "PUT"})
            .then(() => {
                this.setState({loading: false, title: "", body: ""});
                this.props.onResponse(this.props.translate("users.resetDevice.done"), false);
            }, () => {
                this.setState({loading: false});
                this.props.onResponse(this.props.translate("users.resetDevice.failed"), false);
            })

    };

    render() {
        const {loading} = this.state;
        const {translate, open, record} = this.props;
        let title = translate("users.resetDevice.resetTitle");
        if (record !== undefined) {
            title = title + record.name;
        }
        return (
            <Dialog open={open}
                    onClose={this.handleClose}
                    aria-labelledby="form-dialog-title">
                <DialogTitle
                    id="form-dialog-title">{title}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {translate("users.resetDevice.resetMessage")}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button disabled={loading}
                            variant={'text'} color={'primary'}
                            onClick={() => {
                                this.props.onResponse(null, false);
                            }}>
                        {translate("users.resetDevice.cancel")}
                    </Button>
                    <Button disabled={loading} variant={'text'}
                            color={'primary'}
                            onClick={this.handleSend}>
                        {loading && (
                            <CircularProgress size={25} thickness={2}/>
                        )}
                        <SendIcon/>{translate("users.resetDevice.reset")}
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

export const UserResetTranslations = {
    en: {
        users: {
            resetDevice: {
                resetTitle: "Reset device for ",
                resetMessage: "This will allow user login from new device. Are you sure to reset?",
                cancel: "Cancel",
                body: "Body",
                subject: "Subject",
                done: "Device reset successful",
                failed: "Failed to reset device.",
                reset: "Reset Device",
            }
        }
    }
};

export default translate(ResetDeviceView);