import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import compose from 'recompose/compose';
import {withRouter} from 'react-router-dom';

import {DashboardMenuItem, MenuItemLink, Responsive, translate, WithPermissions} from 'react-admin';
import UserIcon from '@material-ui/icons/AccountCircle';
import {CategoryIcon} from "../categories/Category";
import SubMenu from "./SubMenu";
import {DepartmentIcon} from "../categories/Department";

import {ProductIcon} from "../products/Product";
import GroceryIcon from '@material-ui/icons/LocalGroceryStore';
import ReportIcon from '@material-ui/icons/Assessment';
import EmailIcon from '@material-ui/icons/Email';
import OtherIcon from '@material-ui/icons/Settings';
import DepoSaleIcon from '@material-ui/icons/Store';
import RefundIcon from '@material-ui/icons/MoneyOff';

const categories = [{name: 'departments', icon: <DepartmentIcon/>},
    {name: 'categories', icon: <CategoryIcon/>}]

const users = [{name: 'customers', icon: <UserIcon/>}, {
    name: "depoSalePersons",
    icon: <DepoSaleIcon/>
}]
const products = [{name: 'products', icon: <ProductIcon/>}]
const reports = [{name: "saleReports/depo", icon: <DepoSaleIcon/>}]
const others = [{name: "notificationEmails", icon: <EmailIcon/>}]
const refunds = [{name: "depoRefunds", icon: <DepoSaleIcon/>}]

const sales = [{name: "depoSales", icon: <DepoSaleIcon/>}]
const commons = [{label: 'resources.products.name', ...products[0]}, {label: "resources.orders.name", ...products[2]}, {label: "resources.customers.name", ...users[0]}]
const salePersonItems = [
    {label: 'menu.sales', icon: <DepoSaleIcon/>, items: sales, key: 'sales'},
    {label: 'menu.refunds', icon: <RefundIcon/>, items: refunds, key: 'refunds'},
    ...commons
]

const stockManagerItems = [{label: 'resources.products.name', ...products[0]}]

const adminItems = [
    {label: 'resources.categories.name', items: categories, key: 'categories', icon: <DepartmentIcon/>},
    {label: 'menu.products', items: products, key: 'products', icon: <GroceryIcon/>},
    {label: 'menu.users', icon: <UserIcon/>, items: users, key: "users"},
    {label: 'menu.others', icon: <OtherIcon/>, items: others, key: 'others'},
    {label: 'menu.sales', icon: <DepoSaleIcon/>, items: sales, key: 'sales'},
    {label: 'menu.refunds', icon: <RefundIcon/>, items: refunds, key: 'refunds'},
    {label: 'menu.reports', icon: <ReportIcon/>, items: reports, key: 'reports'},
];

class Menu extends Component {
    static propTypes = {
        onMenuClick: PropTypes.func,
        logout: PropTypes.object,
    };
    state = {};

    handleToggle = menu => {
        this.setState(state => ({[menu]: !state[menu]}));
    };

    render() {
        const {onMenuClick, logout, translate, permissions} = this.props;
        let items = []
        let canShowDashboard = false
        if (permissions === "ROLE_ADMIN") {
            items = adminItems;
            canShowDashboard = true
        } else if (permissions === "ROLE_DEPO_SALE_PERSON") {
            items = salePersonItems;
            canShowDashboard = true
        } else if (permissions === "ROLE_STOCK_MANAGER") {
            items = stockManagerItems
            canShowDashboard = false
        } else {
            return <div/>
        }

        return <div>
            {canShowDashboard && <DashboardMenuItem onClick={onMenuClick}/>}
            {items.map(item => {
                if (item.items !== undefined) {
                    return <SubMenu isOpen={this.state[item.key]}
                                    key={item.key}
                                    icon={item.icon}
                                    sidebarIsOpen={true}
                                    handleToggle={() => this.handleToggle(item.key)}
                                    name={item.label}>
                        {item.items.map(subItem => (
                            <MenuItemLink
                                key={subItem.name}
                                to={`/${subItem.name}`}
                                primaryText={translate(`resources.${subItem.name}.name`, {
                                    smart_count: 2,
                                })}
                                leftIcon={subItem.icon}
                                onClick={onMenuClick}
                            />
                        ))}
                    </SubMenu>
                }
                return <MenuItemLink
                    key={item.name}
                    to={`/${item.name}`}
                    primaryText={translate(`resources.${item.name}.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={item.icon}
                    onClick={onMenuClick}
                />
            })}
            <Responsive
                small={logout}
                medium={null}
            />
        </div>
    }
}


const mapStateToProps = state => {
    return {
        theme: state.theme,
    }
};

export const MenuTranslations = {
    en: {
        menu: {
            users: "Müşteri",
            products: "Ürün",
            reports: "Raporlar",
            others: "Ayarlar",
            sales: "Satiş",
            refunds: "İade",
        }
    }
}

const enhance = compose(
    withRouter,
    connect(
        mapStateToProps,
        {}
    ),
    translate
);
const EnhancedMenu = enhance(Menu);
const PermissionMenu = (props) => (
    <WithPermissions render={({permissions}) => <EnhancedMenu {...props}
                                                              permissions={permissions}/>}
                     {...props}/>
);
export default PermissionMenu;