import React from "react";
import {Datagrid, List, ReferenceField, TextField} from 'react-admin'
import {TimeField} from "../common/TimeField";
import Button from "@material-ui/core/Button";
import ReceiptIcon from "@material-ui/icons/Receipt";
import {SERVER_IP} from "../index";
import {Filters} from "../report/ProductSaleReport";
import {MoneyField} from "../common/MoneyField";


export const SaleList = props => (
    <List {...props}
          sort={{field: "time", order: "DESC"}}
          exporter={false}
          filters={<Filters/>}
          bulkActionButtons={false}>
        <SaleGrid/>
    </List>
);
export const SaleGrid = (props) => {
    return <Datagrid {...props}>
        <TextField source={'id'}/>
        <TimeField source={'time'}/>
        <MoneyField source={'total'}/>
        <MoneyField source={'discount'}/>
        <MoneyField source={'deliveryFee'}/>
        <MoneyField source={'finalPrice'}/>
        <ReferenceField reference={'customers'} source={'customer.id'}>
            <TextField source={'name'}/>
        </ReferenceField>
        <ReceiptButton {...props}/>
    </Datagrid>
}
const ReceiptButton = ({resource, record}) => {
    const openReceipt = () => {
        if (record) {
            let token = localStorage.getItem("token");
            window.open(`${SERVER_IP}/${resource}/receipt/${record.id}?token=${token}`,
                "_blank")
        }
    }
    return <Button variant={'text'}
                   color={'primary'}
                   onClick={() => openReceipt()}
                   startIcon={<ReceiptIcon/>}>
        Makbuz/Fiş
    </Button>
}

export const SaleTranslations = {
    en: {
        depoSales: {
            name: 'Satişler',
            fields: {
                id: "#",
                product: "Barkod ile ürün ara",
                quantity: "Miktar",
                total: 'Toplam fiyat',
                finalPrice: 'Son fiyat',
                itemPrice: "Adet fiyat",
                price: "Toplam fiyat",
                discount: "Indirim",
                deliveryFee: "Ekstra ücret",
                barcode: "Barkod",
                customer: {
                    id: 'Müşteri'
                }
            }
        }
    }
}