import React, {Fragment, useEffect, useState} from "react";
import ResetIcon from "@material-ui/icons/History"
import {Button} from 'react-admin'
import {Dialog, DialogActions, MuiThemeProvider} from "@material-ui/core";
import {createMuiTheme} from "@material-ui/core/styles";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import CircularProgress from "@material-ui/core/CircularProgress";
import {SERVER_IP} from "../index";
import {fetchJson} from "../rest/fetch";
import {lightTheme} from "../layout/Layout";
import MaterialButton from '@material-ui/core/Button'
import Typography from "@material-ui/core/Typography";

function ResetStockButton() {
    const [open, setOpen] = useState(false)
    const [loading, setLoading] = useState(false)
    const [count, setCount] = useState(0)

    function handleClose() {
        setOpen(false)
    }

    useEffect(() => {
        if (!open) {
            return
        }
        setLoading(true)
        fetchJson(`${SERVER_IP}/products/resetStockInfo`)
            .then(({json}) => {
                setCount(json.count)
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                setLoading(false)
            })
    }, [open])

    function resetStock() {
        setLoading(true)
        fetchJson(`${SERVER_IP}/products/resetStock`,
            {method: "POST"})
            .then(() => {
                setOpen(false)
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                setLoading(false)
            })
    }


    return (
        <Fragment>
            <Button color={'primary'} onClick={() => setOpen(true)}
                    label={"Reset Stock"}>
                <ResetIcon/>
            </Button>
            <Dialog open={open}

                    onClose={handleClose}
                    aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title" color={'secondary'}>
                    Reset Stock
                </DialogTitle>
                <DialogContent>
                    <Typography variant={'body1'}>
                        Reset stock for all the items where keep alive option is false. System will reset
                        total <b>{count}</b> product{count > 1 ? 's' : ''}. This action is reversible.
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <MaterialButton disabled={loading}
                                    variant={'text'} color={'primary'}
                                    onClick={() => {
                                        setOpen(false)
                                    }}>
                        Cancel
                    </MaterialButton>
                    <MaterialButton disabled={loading || count === 0} variant={'text'}
                                    color={'primary'}
                                    onClick={resetStock}>
                        {loading && (
                            <CircularProgress size={25} thickness={2}/>
                        )}
                        Reset
                    </MaterialButton>
                </DialogActions>
            </Dialog>
        </Fragment>
    );
}

const Themed = props => (
    <MuiThemeProvider theme={createMuiTheme(lightTheme)}>
        <ResetStockButton {...props}/>
    </MuiThemeProvider>
)
export default Themed;