import englishMessages from 'ra-language-english';
import {ImageUploadTranslation} from "../common/ImageUpload";
import {UserTranslations} from "../user/User";
import {CategoryTranslations} from "../categories/Category";
import {UserResetTranslations} from "../user/ResetDeviceView";
import {DepartmentTranslations} from "../categories/Department";
import {MenuTranslations} from "../layout/Menu";
import {ProductTranslations} from "../products/Product";
import {OrderReportTranslations} from "../report/OrderReport";
import {NotificationEmailTranslations} from "../settings/NotificationEmail";
import {ConfigurationStrings} from "../settings/Configuration";
import {DeliverOrderTranslations} from "../products/DeliverOrderView";
import {MarkOutOfStockTranslations} from "../products/MarkOutOfStockView";
import {ProductsListActionTranslations} from "../products/ProductsListBulkActions";
import {SaleTranslations} from "../sale/SaleList";
import {RefundTranslations} from "../sale/RefundList";
import {SaleReportTranslations} from "../report/ProductSaleReport";
import {DepoSaleUserTranslations, StockManagerUserTranslations} from "../user/SaleUser";

export default {
    ...englishMessages,
    ...ImageUploadTranslation.en,
    login: {
        loginFailed: "Login failed. Check username or password"
    },

    resources: {
        ...UserTranslations.en,
        ...CategoryTranslations.en,
        ...DepartmentTranslations.en,
        ...ProductTranslations.en,
        ...OrderReportTranslations.en,
        ...NotificationEmailTranslations.en,
        ...ConfigurationStrings.en,
        ...SaleTranslations.en,
        ...RefundTranslations.en,
        ...SaleReportTranslations.en,
        ...DepoSaleUserTranslations.en,
        ...StockManagerUserTranslations.en,
    },
    ...UserResetTranslations.en,
    ...MenuTranslations.en,
    ...DeliverOrderTranslations.en,
    ...MarkOutOfStockTranslations.en,
    ...ProductsListActionTranslations.en
}