import * as React from 'react';
import {cloneElement} from 'react';
import {CreateButton, sanitizeListRestProps, TopToolbar, translate,} from 'react-admin';
import {ExportButton} from "../common/ExportButton";

export const UserListActions = translate(({
                                              currentSort,
                                              className,
                                              resource,
                                              filters,
                                              displayedFilters,
                                              exporter,
                                              filterValues,
                                              permanentFilter,
                                              hasCreate,
                                              basePath,
                                              selectedIds,
                                              onUnselectItems,
                                              showFilter,
                                              maxResults,
                                              total,
                                              translate,
                                              excelExporter,
                                              permissions,
                                              ...rest
                                          }) => (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
        {filters && cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: 'button',
        })}
        {hasCreate && <CreateButton basePath={basePath}/>}
        {permissions === 'ROLE_ADMIN' && <ExportButton path={"customers"} pdf={true} total={total}/>}
        {permissions === 'ROLE_ADMIN' && <ExportButton path={"customers"} pdf={false} total={total}/>}
    </TopToolbar>
));

