import React from "react";

export const TimeField = ({record, source}) => {
    if (record[source] < 1 || record[source] === undefined) {
        return <span>NA</span>
    } else {
        let date = new Date(record[source]);
        return <span>{date.toLocaleString()}</span>;
    }
};

