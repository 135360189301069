import React from 'react';

import englishMessages from './i18n/en';
import trMessages from './i18n/tr';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import {Admin, Resource} from 'react-admin';
import AuthClient from "./login/AuthClient";
import {SERVER_IP} from "./index";
import OurClient from "./rest/OurClient";
import CustomLayout from "./layout/Layout";
import Login from "./login/Login"
import {CategoryCreate, CategoryEdit, CategoryList, CategoryShow} from "./categories/Category";
import {UserCreate, UserEdit, UserList, UserShow} from "./user/User";
import {DepartmentCreate, DepartmentEdit, DepartmentList, DepartmentShow} from "./categories/Department";
import {ProductCreate, ProductEdit, ProductList, ProductShow, StockManagerProductEdit} from "./products/Product";
import Dashboard from "./dashboard/Dashboard";
import {NotificationEmailCreate, NotificationEmailEdit, NotificationEmailList} from "./settings/NotificationEmail";
import routes from "./routes";
import {SaleList} from "./sale/SaleList";
import {RefundCreate, SaleCreate} from "./sale/SaleCreate";
import {RefundList} from "./sale/RefundList";
import {ListSaleReport} from "./report/ProductSaleReport";
import {SalePersonCreate, SalePersonEdit, SalePersonList, SalePersonShow} from "./user/SaleUser";

const messages = {
    en: {...englishMessages},
    tr:{...trMessages}
};


const i18nProvider = polyglotI18nProvider(() => messages.tr, 'tr');

function App() {
    return (
        <Admin dataProvider={OurClient(SERVER_IP)}
               authProvider={AuthClient}
               loginPage={Login}
               dashboard={Dashboard}
               customRoutes={routes}
               title={"Babaeski Depo"}
               i18nProvider={i18nProvider}
               layout={CustomLayout}>
            {permissions => [
                permissions === "ROLE_ADMIN" && <Resource name={'departments'}
                                                          list={DepartmentList}
                                                          edit={DepartmentEdit}
                                                          show={DepartmentShow}
                                                          create={DepartmentCreate}/>,

                permissions === "ROLE_ADMIN" && <Resource name={'stockManagerPersons'}
                                                          list={SalePersonList}
                                                          edit={SalePersonEdit}
                                                          show={SalePersonShow}
                                                          create={SalePersonCreate}/>,

                permissions === "ROLE_ADMIN" && <Resource name={'depoSalePersons'}
                                                          list={SalePersonList}
                                                          edit={SalePersonEdit}
                                                          show={SalePersonShow}
                                                          create={SalePersonCreate}/>,

                permissions === "ROLE_ADMIN" && <Resource name={'notificationEmails'}
                                                          list={NotificationEmailList}
                                                          edit={NotificationEmailEdit}
                                                          create={NotificationEmailCreate}/>,

                permissions === "ROLE_ADMIN" && <Resource name={'saleReports/depo'}
                                                          list={ListSaleReport}/>,


                (permissions === "ROLE_ADMIN" || permissions === "ROLE_DEPO_SALE_PERSON") &&
                <Resource name={'depoSales'}
                          list={SaleList}
                          create={SaleCreate}/>,

                (permissions === "ROLE_ADMIN" || permissions === "ROLE_DEPO_SALE_PERSON") &&
                <Resource name={'depoRefunds'}
                          list={RefundList}
                          create={RefundCreate}/>,

                <Resource name={'customers'}
                          list={UserList}
                          edit={permissions === "ROLE_ADMIN" ? UserEdit : undefined}
                          show={UserShow}
                          create={permissions === "ROLE_ADMIN" ? UserCreate : undefined}/>,

                <Resource name={"categories"}
                          list={permissions === "ROLE_ADMIN" ? CategoryList : undefined}
                          edit={permissions === "ROLE_ADMIN" ? CategoryEdit : undefined}
                          show={permissions === "ROLE_ADMIN" ? CategoryShow : undefined}
                          create={permissions === "ROLE_ADMIN" ? CategoryCreate : undefined}/>,
                <Resource create={permissions === "ROLE_ADMIN" ? ProductCreate : undefined}
                          list={ProductList}
                          edit={ProductEditWithPermission(permissions)}
                          show={ProductShow}
                          name={'products'}/>,
            ]
            }
        </Admin>
    );
}

function ProductEditWithPermission(permission) {
    if (permission === "ROLE_ADMIN") {
        return ProductEdit
    } else if (permission === "ROLE_STOCK_MANAGER") {
        return StockManagerProductEdit
    }
    return undefined
}

export default App;
