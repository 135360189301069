import React from "react";
import {translate} from "react-admin";
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import enLocale from "date-fns/locale/en-US";


export const DatePickerInput = translate(({translate, label, min, max, input, disabled, className}) => {
    if (input.value < 1000) {
        let date = new Date();
        input.onChange(date.getTime())
    }
    return <MuiPickersUtilsProvider
        utils={DateFnsUtils} locale={enLocale}>
        <DatePicker
            disabled={disabled}
            className={className}
            value={input.value}
            maxDate={max}
            minDate={min}
            label={translate(label)}
            ampm={false}
            inputVariant="filled"
            size={'small'}
            format={"yyyy-MM-dd"}
            onChange={(date) => {
                input.onChange(date.getTime())
            }}
        />
    </MuiPickersUtilsProvider>
});