import React, {useState} from "react";
import {Field} from 'react-final-form';
import TextField from '@material-ui/core/TextField';

export const SearchInput = ({source, label, ...props}) => {
    const [value, setValue] = useState('')

    return (
        <span style={{marginBottom: '.5em'}}>
        <Field name={source}>
            {props => {
                if (!value && props.input.value) {
                    props.input.onChange('')
                }

                return (
                    <div>
                        <TextField
                            name={props.input.name}
                            size='small'
                            variant={'filled'}
                            value={value}
                            onChange={(ev) => {
                                ev.persist();
                                setValue(ev.target.value);
                                if (!ev.target.value) {
                                    props.input.onChange('')
                                }
                            }}
                            onKeyPress={(e) => {
                                e.persist();
                                if (e.key === 'Enter') {
                                    props.input.onChange(value)
                                } else {
                                    return null
                                }
                            }}
                            label={label}
                        />
                    </div>
                )
            }}
        </Field>
        </span>
    )
}