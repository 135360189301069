import React, {useState} from "react";
import ChipInput from "material-ui-chip-input";

export function StringArrayInput({input, label}) {
    const [chips, setChips] = useState(input.value ? input.value : [])

    const handleAddChip = chip => {
        chips.push(chip);
        setChips(chips)
        input.onChange(chips)
    }
    const handleDeleteChip = (chip, index) => {
        chips.splice(index, 1);
        setChips(chips)
        input.onChange(chips)
    }

    return <ChipInput allowDuplicates={false}
                      value={chips}
                      label={label}
                      variant={'filled'}
                      onDelete={handleDeleteChip}
                      onAdd={handleAddChip}/>
}