import React, {useEffect, useState} from 'react';
import {Card, CircularProgress, makeStyles} from "@material-ui/core";
import {CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis} from 'recharts';
import {fetchJson} from "../rest/fetch";
import {SERVER_IP} from "../index";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import enLocale from "date-fns/locale/en-US";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles(theme => ({
    main: {
        flex: '1',
        margin: '1em',
    },
    titleLink: {textDecoration: 'none', color: 'inherit'},
    card: {
        padding: '16px 0',
        overflow: 'inherit',
        textAlign: 'right',
        height: '470px'
    },
    title: {
        padding: '0 16px',
    },
    value: {
        padding: '0 16px',
        minHeight: 48,
    },
    avatar: {
        background: theme.palette.background.avatar,
    },
    formControl: {
        marginRight: '1em',
    },

}));


const providerOptions = {
    utils: DateFnsUtils,
    locale: enLocale,
};

function sevenDays() {
    let date = new Date()
    date.setTime(date.getTime() - 7 * 24 * 60 * 60 * 1000)
    return date
}

export function SaleCountChart({refund = false}) {
    const classes = useStyles()
    const [start, setStart] = useState(sevenDays())
    const [end, setEnd] = useState(new Date())
    const [loading, setLoading] = useState(false)
    const [period, setPeriod] = useState("DAY")
    const [data, setData] = useState([])

    useEffect(() => {
        setLoading(true)
        let body = {
            start: start.getTime(),
            end: end.getTime(),
            period: period,
            refund: refund
        }
        let s = JSON.stringify(body)
        fetchJson(`${SERVER_IP}/saleReports/shop/getCount`, {body: s, method: "POST"})
            .then(response => {
                setLoading(false)
                if (response.json) {
                    setData(response.json)
                }
            }, () => {
                console.log("Error occurred")
                setLoading(false)
            })
    }, [start, end, period, refund])


    const handleChange = (event) => {
        let previousValue = period;
        let newValue = event.target.value;
        if (previousValue === newValue) {
            return
        }
        setPeriod(newValue)
    };


    const handleStartDate = date => {
        setStart(date)
    };

    const handleEndDate = date => {
        setEnd(date)
    };

    return (
        <div className={classes.main}>
            <Typography variant={'body2'}>
                {refund ? "İade" : "Satiş"}
            </Typography>
            <Card className={classes.card} variant={"outlined"}>

                <MuiPickersUtilsProvider {...providerOptions}>
                    <DatePicker style={{marginRight: "1em", marginLeft: '1em'}}
                                value={start}
                                format="dd/MM/yyyy"
                                name={"startDate"}
                                maxDate={new Date()}
                                label="Başlangıç tarihi"
                                views={["year", "month", "date"]}
                                onChange={handleStartDate}/>
                </MuiPickersUtilsProvider>

                <MuiPickersUtilsProvider {...providerOptions}>

                    <DatePicker style={{marginRight: "1em", marginLeft: "1em"}}
                                value={end}
                                format="dd/MM/yyyy"
                                label="Bitiş tarihi"
                                maxDate={new Date()}
                                name="endDate"
                                minDate={start}
                                views={["year", "month", "date"]}
                                onChange={handleEndDate}/>
                </MuiPickersUtilsProvider>

                <FormControl className={classes.formControl}>
                    <InputLabel htmlFor="demo-controlled-open-select">Period</InputLabel>
                    <Select
                        value={period}
                        onChange={handleChange}
                        inputProps={{
                            name: 'period',
                            id: 'demo-controlled-open-select',
                        }}>
                        <MenuItem value={"DAY"}>Gun</MenuItem>
                        <MenuItem value={"MONTH"}>Ay</MenuItem>
                        <MenuItem value={"YEAR"}>Yil</MenuItem>
                    </Select>
                </FormControl>

                {!loading && <ResponsiveContainer height={'87%'} width={'99%'}>
                    <LineChart data={data}>
                        <CartesianGrid vertical={false} strokeDasharray="3"/>
                        <XAxis dataKey="name" tick={{fill: '#212121'}} stroke={'white'}/>
                        <YAxis dataKey={'value'}/>
                        <Tooltip/>
                        <Line dataKey="value" name={refund ? "İade" : "Satiş"} strokeWidth={2}
                              stroke={refund ? "#3d5afe" : "#4caf50"}/>
                    </LineChart>
                </ResponsiveContainer>}
                {loading && <div style={{textAlign: 'center', height: '100%'}}>
                    <CircularProgress color={'primary'}/>
                </div>}

            </Card>
        </div>
    )
}
