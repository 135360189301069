import React from "react";
import {Datagrid, List, TextField} from 'react-admin'
import {TimeField} from "../common/TimeField";
import Button from "@material-ui/core/Button";
import ReceiptIcon from "@material-ui/icons/Receipt";
import {SERVER_IP} from "../index";
import {Filters} from "../report/ProductSaleReport";


export const RefundList = props => (
    <List {...props}
          sort={{field: "time", order: "DESC"}}
          exporter={false}
          filters={<Filters/>}
          bulkActionButtons={false}>
        <Datagrid>
            <TextField source={'id'}/>
            <TimeField source={'time'}/>
            <ReceiptButton {...props}/>
        </Datagrid>
    </List>
);

const ReceiptButton = ({resource, record}) => {
    const openReceipt = () => {
        if (record) {
            let token = localStorage.getItem("token");
            window.open(`${SERVER_IP}/${resource}/receipt/${record.id}?token=${token}`,
                "_blank")
        }
    }
    return <Button variant={'text'}
                   color={'primary'}
                   onClick={() => openReceipt()}
                   startIcon={<ReceiptIcon/>}>
        Receipt
    </Button>
}

export const RefundTranslations = {
    en: {
        depoRefunds: {
            name: 'İadeler',
            fields: {
                id: "#",
                product: "Barkod ile ürün ara",
                quantity: "Miktar",
                itemPrice: "Fiyat",
                price: "Toplam fiyat",
                barcode: "Barkod",
            }
        }
    }
}