import React from "react";
import {
    BooleanField,
    BooleanInput,
    Create,
    Datagrid,
    Edit,
    EditButton,
    Filter,
    List,
    maxLength,
    minLength,
    PasswordInput,
    ReferenceManyField,
    required,
    Show,
    ShowButton,
    SimpleForm,
    Tab,
    TabbedShowLayout,
    TextField,
    TextInput,
} from 'react-admin';
import {ListPagination} from "../common/Pagination";
import {TimeField} from "../common/TimeField";
import {SearchInput} from "../common/SearchInput";
import {UserListActions} from "./UserListActions";
import {SaleGrid} from "../sale/SaleList";


const UserFilters = (props) => (
    <Filter {...props}>
        <SearchInput source={'name'} label={"Isim"} alwaysOn/>
        <SearchInput source={'username'} label={"Telefon"} alwaysOn/>
    </Filter>
);

export const UserCreate = (props) => (
    <Create {...props}>
        <SimpleForm redirect={'list'}>
            <TextInput source={"name"}
                       validate={[required(), maxLength(100)]}/>
            <BooleanInput source={"enabled"}/>
            <TextInput source={"username"} validate={[required(), minLength(4), maxLength(30)]}/>
            <PasswordInput source={"password"} validate={[required(), minLength(6), maxLength(30)]}/>
        </SimpleForm>
    </Create>
);

export const UserEdit = (props) => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput source={"name"} validate={required()}/>
        </SimpleForm>
    </Edit>
);


export const UserList = ({permissions, ...props}) => (
    <List {...props} bulkActionButtons={false}
          sort={{field: 'id', order: 'DESC'}}
          actions={<UserListActions permissions={permissions}/>}
          pagination={<ListPagination/>}
          perPage={50}
          filters={<UserFilters/>}>
        <Datagrid>
            <TextField source={"name"}/>
            <TextField source={"username"}/>
            <TimeField source={'creationTime'}/>
            <ShowButton/>
            {permissions === "ROLE_ADMIN" && <EditButton/>}
        </Datagrid>
    </List>
);

export const UserShow = ({permissions, ...props}) => (
    <Show {...props}>
        <TabbedShowLayout>
            <Tab label={'resources.customers.general'}>
                <TextField source={"name"}/>
                <TextField source={"username"}/>
                <BooleanField source={"enabled"}/>
            </Tab>

            <Tab label={'resources.customers.orders'}>
                <ReferenceManyField reference={'depoSales'} target={'customer.id'} addLabel={false}>
                    <SaleGrid/>
                </ReferenceManyField>
            </Tab>
        </TabbedShowLayout>
    </Show>
);


export const UserTranslations = {
    en: {
        customers: {
            name: "Müşteriler",
            general: "Genel",
            orders: 'Satışler',
            fields: {
                name: "Isim",
                username: "Telefon",
                creationTime: "Eklendi Zaman",
            }
        }
    }
};