import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import {SERVER_IP} from "../index";


export default function CustomerSearchField({onChange}) {
    const [open, setOpen] = React.useState(false);
    const [options, setOptions] = React.useState([]);
    const [input, setInput] = React.useState("")
    const [loading, setLoading] = React.useState(false)
    const [value, setValue] = React.useState(null)
    React.useEffect(() => {
        let active = true;
        let filter = "";
        if (input !== null && input !== undefined && input.trim().length > 0) {
            filter = "?" + encodeURI(`filter={"name":"${input}","username":"${input}"}`) + "&or=true"
        }
        const headers = new Headers();
        headers.append("Authorization", `Bearer ${localStorage.getItem("token")}`)
        let abort = new AbortController()
        setLoading(true)
        fetch(`${SERVER_IP}/customers/get${filter}`,
            {headers: headers, signal: abort.signal})
            .then(response => response.json())
            .then(data => {
                if (active) {
                    setLoading(false)
                    setOptions(data.content)
                }
            })
            .catch(e => {
                setLoading(false)
                console.log(e)
            })

        return () => {
            abort.abort()
            active = false;
        };
    }, [input]);

    React.useEffect(() => {
        if (!open) {
            setOptions([]);
        }
    }, [open]);

    return (
        <Autocomplete
            id="search-customer"
            style={{width: 300}}
            open={open}
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() => {
                setOpen(false);
            }}
            getOptionSelected={(option, value) => option.id === value.id}
            getOptionLabel={(option) => option.name}
            options={options}
            autoComplete
            loading={loading}
            inputValue={input}
            value={value}
            onInputChange={e => setInput(e.target.value)}
            onChange={(e, newValue) => {
                onChange(newValue)
                setValue(newValue)
                setInput(newValue === null ? "" : newValue.name)
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label="Muüşteri Ara"
                    variant="outlined"
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {loading ? <CircularProgress color="inherit" size={20}/> : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                />
            )}
        />
    );
}
