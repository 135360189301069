import * as React from 'react';
import {Fragment, useState} from 'react';
import {translate, useRefresh, useUnselectAll} from 'react-admin';
import {Snackbar} from "@material-ui/core";
import MarkOutOfStockView from "./MarkOutOfStockView";
import Button from "@material-ui/core/Button";

export const ProductsListBulkActions = translate(({
                                                      selectedIds,
                                                      ...props
                                                  }) => {
        const [state, setState] = useState({open: false, message: "", showMessage: false});
        const refresh = useRefresh();
        const unselectAll = useUnselectAll();
        return <Fragment>
            <Button
                disabled={selectedIds === 0}
                onClick={() => setState({open: true, message: "", showMessage: false})}
                color={'secondary'}>
                Stok Bitmiş
            </Button>
            <MarkOutOfStockView open={state.open}
                                onResponse={(message, open) => {
                                    refresh()
                                    unselectAll('products');
                                    setState({showMessage: message !== null, message: message, open: open})
                                }}
                                record={selectedIds}/>
            <Snackbar open={state.showMessage}
                      message={state.message}
                      autoHideDuration={5000}
                      onClose={() => {
                          setState({open: false, message: "", showMessage: false})
                      }}/>

        </Fragment>
    }
);

export const ProductsListActionTranslations = {
    en: {
        productListActions: {
            exportExcel: "Export",
        }
    }
}