import * as React from "react";
import {useState} from "react";
import {serverExport} from "../Util";
import {Button} from "react-admin";
import PdfIcon from "@material-ui/icons/PictureAsPdf";
import ExcelIcon from "@material-ui/icons/FormatAlignCenter";
import {CircularProgress} from "@material-ui/core";

export function ExportButton({path, pdf, total}) {
    const [loading, setLoading] = useState(false)

    function doExport() {
        setLoading(true)
        serverExport(path, pdf, () => {
            setLoading(false)
        })
    }

    return <Button startIcon={pdf ? <PdfIcon/> : <ExcelIcon/>}
                   label={pdf ? "PDF Export" : "Excel Export"}
                   disabled={total === 0 || loading}
                   onClick={() => doExport()}
                   color={'primary'}>
        {loading ? <CircularProgress size={16} color={'secondary'}/> : undefined}
    </Button>
}
