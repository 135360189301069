import React from 'react';
import {SaleCountChart} from "./SaleCountChart";
import {SaleRefundCountChart} from "./SaleRefundCountChart";
import {SaleMoneyChart} from "./SaleMoneyChart";

const styles = {
    flex: {display: 'flex', marginBottom: '2em'},
    leftCol: {flex: 1, margin: '1em'},
    rightCol: {flex: 1, margin: '1em'},
    singleCol: {marginTop: '2em'},
};

export default class Dashboard extends React.Component {
    render() {
        const {permissions} = this.props;
        if (permissions !== "ROLE_ADMIN") {
            return <h1>Welcome Stock Manager</h1>
        }
        return (
            <div>
                <div style={styles.flex}>
                    <div style={styles.rightCol}>
                        <SaleMoneyChart/>
                    </div>
                </div>
                <div style={styles.flex}>
                    <div style={styles.leftCol}>
                        <SaleCountChart refund={false}/>
                    </div>
                    <div style={styles.rightCol}>
                        <SaleCountChart refund={true}/>
                    </div>
                </div>
                <div style={styles.flex}>
                    <div style={styles.leftCol}>
                        <SaleRefundCountChart/>
                    </div>
                </div>
            </div>
        );
    }
}
